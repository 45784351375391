export const environment = {
  Wp: false,
  production: true,
  apiUrl: 'https://aba.wcs-servizi.it/api',
  bbbUrl: 'https://newsbs.ddns.net/bigbluebutton',
  langPrefix: './assets/i18n/',
  langSuffix: '.json',
  loginbgPath: 'assets/images/background/teamwork-3213924.jpeg',
  icon:'assets/images/KlimaIMGxicon.png',
  baseUrl: '/task/calendario',
  href: '/Login',
  homepages: [
    // {Ruoli: ["AC"], link: "/Cliente/profile"}
  ],
  logoPath: 'assets/images/wcs.jpeg',
  loginMsg: '',
  logoBg: 'skin6', // six possible values: skin(1/2/3/4/5/6),
  navbarbg: 'skin2', // six possible values: skin(1/2/3/4/5/6)
  sidebarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
  userCircleColor: "",
  theme: 'dark',
  dir: 'ltr', // two possible values: ltr, rtl
  layout: 'vertical', // fixed value. shouldn't be changed.
  sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
  sidebarpos: 'fixed', // two possible values: fixed, absolute
  headerpos: 'fixed', // two possible values: fixed, absolute
  boxed: 'full', // two possible values: full, boxed
  ClientiType: ['C', 'F'],
  IDTecnico: "RT",
  //#region Moduli
  Modules:{
    "twoFactorAuth": false,
    "Admin": true,
    "Personale": true,
    "Clienti": true,
    "Interventi": true,
    "Prima_Nota": false,
    "Listini": false,
    "Progetti": true,
    "Prodotti": true,
    "Documenti": true,
    "Scadenzario": false,
    "Spedizioni": false,
    "Stampe": false,
    "Task": true,
    "Shared": true,
    "Dashboard": false,
    "Settings": true,
    "Formazione": true,
    "VersioneCarbotti": false,
    "PartiProdotto": false,
    "Parti": false,
    "Rma": true,
    "Ecommerce": false,
    "ScadenzeFiglie": false,
    "Registrazione": false,
    "Ticket": true,
    "Acquisti": false,
    "taskboard": true,
    "Camera":false,
    "Valutazioni":false
  },
  //#endregion
  //#region Addon
  TaskAddon: {
    checkList : true,
  },
  ProgettiAddon: {
    commerciale: false,
    segnalatore: false,
    categoriaVoceDiCosto: false
  },
  ClientiAddOn: {
    codAdhoc:false,
    enable:false,
    info:true,
    commerciale: true,
    segnalatore: true,
    segnalatoreInterno: true,
    sedi: true,
    TipoSedePrimaria: false,
    attivita: true,
    geolocalizzazione: false,
    ruolo: true,
    Modifica: false,
    prodotti: true,
    rma: false,
    accesso: false,
    referenti: false,
    interventi: true,
    note_private:false,
    rating : false,
    ruoliAccesso:false,
    compilazione:false,
    luogoNascita: false,
    dataNascita: false,
    statoCivile:false,
    titoloStudio: false,
    occupazione: false,
    invalidita: false,
    legge104: false,
    diagnosi: false,
    accompagnamento:false,
    pensione: false,
    tutelare: false,
    medico: false,
    telMedico: false,
    terapia:false
  },
  ProdottiAddOn: {
    sottoTitolo: false,
    standby: false,
    tipi:false, // tipi di prodotto (1 - 2ante klima)
    shortDescription: false,
    classeEnergetica: false,
    tags: false,
    video: false,
    dettaglio:false,
    files: true,
    cart: false,
    codice: false,
    codice_produttore: false,
    tipo: true,
    cat_image: true,
    lista_prezzo:true,
    TranslatedDesc: false,
    codAdhoc:false,
    DragSort: false,
    cat_Sort: false,
  },
  legenda:[],
  filtrife: [
    {id:"T", label: "Tutti", capacita: [], module: undefined},
    {id:"R", label: "RMA", capacita: [], module: undefined},
    {id:"A",label:"Attivita", capacita: [], module: undefined},
    {id:"C",label:"Attivita Cliente", capacita: [], module: undefined},
    {id:"S", label: "Scadenze", capacita: [], module: undefined},
    {id:"F",label:"Fasi", capacita: [], module: undefined},
    {id:"L", label: "Lezioni", capacita: [], module: undefined},
    {id:"B",label:"Spese", capacita: [], module: undefined},
    {id:"I", label: "Fatture", capacita: [], module: undefined}
  ],
  CalendarAddon: {
    managerScadenza : true,
  },
  PartiAddOn: [false],//  categorie associate
  RmaAddOn: {
    taskGenerico: false,
    vociDiCosto: false,
    provaAcquisto:true,
    CategoriaProdotto: true,
    TipoAcquisto: false,
    TipoAcquistoSource: "ClientiType",
    Rivenditore:true,
    NumeroDocumento: false,
    DataDocumento: true,
    Ricevuta: false,
    Targhetta: false,
    CircostanzaDifetto: false,
    DescrizioneDifetto: false,
    Difetto: true,
    Telefono: true,
    Garanzia: true,
    EstensioneGaranzia: true,
    Allegati: true,
    Modello: true,
    Matricola: true,
    Brand: false,
    sottoTask: true,
    Ritiro: {
      Enabled: true,
      Referente: true,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true
    },
    Cliente: {
      Enabled: true,
      Referente: true,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true
    },
    Assistenza: {
      Enabled: false,
      Referente: true,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true,
      Tecnico: false,
    }
  },
  Personale:{
    Corsi: false,
    Attivita: false,
    File: true,
  },
  //#endregion
  //#region Menu
  Menu: [
    {
      title: "Menu",
      class: "has-arrow",
      label: "Menu",
      extralink: true
    },
    {
      module: "Dashboard",
      path: "dashboard",
      title: 'menu.dashboard',
      icon: "mdi mdi-gauge",
      capacita: ["menu-dashboard"]
    },
    {
      module: "Personale",
      path: "/listautenti/personale",
      title:'menu.PersonaleLabel',
      icon: "far fa-user",
      capacita: ["menu-persone"]
    },
    // {
    //   module: "Clienti",
    //   capacita: ["menu-clienti"],
    //   path: "/listautenti/aziende/c",
    //   title: 'Clienti',
    //   icon: "mdi mdi-account-multiple",
    // },
    // {
    //   module: "Clienti",
    //   capacita: ["menu-clienti"],
    //   path: "/listautenti/aziende/f",
    //   title: 'Fornitori',
    //   icon: "mdi mdi-account-multiple",
    // },
    {
      module: "Clienti",
      path: "/listautenti/aziende",
      title:'menu.clienti',
      icon: "mdi mdi-account-multiple",
      capacita: ["menu-clienti"]
    },
    {
      module:"Progetti",
      path: "progetti/progetti",
      title: 'menu.progetti',
      icon: "mdi mdi-calendar-multiple-check",
      capacita: ["menu-progetti"]
    },
    {
      module: "Task",
      path: "task/calendario",
      title: 'menu.calendario',
      icon: "mdi mdi-calendar",
    },
    {
      module: "Task",
      path: "task/list",
      title: 'menu.lista',
      icon: "mdi mdi-calendar"
    },
    {
      module: "Listini",
      path: "listino/listini",
      title: 'menu.listini',
      icon: "mdi mdi-calendar"
    },
    {
      module: "Acquisti",
      path: "acquisti/acquisti",
      title: 'menu.acquisti',
      icon: "mdi mdi-calendar"
    },
    {
      module: "Interventi",
      title: "Attività",
      icon: "mdi mdi-wrench",
      class: "has-arrow",
      capacita: ["menu-fasi"],
      submenu: [
        {
          path: "interventi/interventi",
          title: 'menu.interventi',
          icon: "mdi mdi-wrench",
        },
        {
          module: "taskboard",
          path: "task/attivita",
          title: 'menu.taskboard',
          icon: "mdi mdi-calendar"
        },
        {
          module: "taskboard",
          path: "task/attivita/lista",
          title: 'menu.taskboardList',
          icon: "mdi mdi-calendar"
        }
      ]
    },
    {
      module: "Documenti",
      path: "progetti/documenti",
      title: 'menu.DocumentiLabel',
      icon: "mdi mdi-file-document",
      capacita: ["menu-documenti"]
    },
    {
      module: "Prodotti",
      path: "progetti/prodotti",
      title: 'menu.ProdottiLabel',
      icon: "mdi mdi-barcode-scan",
      capacita: ["menu-prodotti"]
    },
    // {
    //   path: "formazione/media",
    //   title: "menu.corsiMultimediali",
    //   icon: "mdi mdi-currency-eur",
    //   capacita: ["menu-formazione"]
    // },
    {
      module: "Formazione",
      title: 'menu.FormazioneLabel',
      icon: "mdi mdi-school",//mdi-calendar-multiple-check //mdi-engine-outline
      class: "has-arrow",
      capacita: ["menu-servizi", "menu-formazione", "menu-calendar-servizi","menu-utenti-servizi"],
      submenu: [
        {
          path: "formazione/media",
          title: "menu.corsiMultimediali",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-formazione"]
        },
        {
          path: "formazione/corsi",
          title: "menu.corsi",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-calendar-servizi", "menu-servizi"]
        },
        {
          path: "/listautenti/personale/mod",
          title: "menu.docenti",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-utenti-servizi"]
        },
        {
          path: "/listautenti/personale/tut",
          title: "menu.tutor",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-utenti-servizi"]
        },
        {
          path: "/listautenti/personale/stu",
          title: "menu.studenti",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-utenti-servizi"]
        },
        {
          path: "formazione/materie",
          title: "menu.materie",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu_calendar_tirocini"]
        },
        {
          path: "formazione/sedi",
          title: "menu.sedi",
          icon: "mdi mdi-currency-eur",
        }
      ]
    },
    {
      module: "Prima_Nota",
      title: "menu.Prima_NotaLabel",
      icon: "mdi mdi-cart",//mdi-calendar-multiple-check //mdi-engine-outline
      class: "has-arrow",
      capacita: ["menu-spese","menu-fatture"],
      submenu: [
        {
          path: "primanota/fatture",
          title: "menu.fattVendita",
          capacita: ["menu-fatture"]
        },
        // {
        //   path: "/listautenti/aziende/f",
        //   title: "menu.fornitori",
        //   capacita: ["menu-fatture"]
        // },
        {
          path: "primanota/primanota",
          title: "menu.primanota",
          capacita: ["menu-spese"]
        },
        {
          path: "primanota/lista-spese",
          title: "menu.listaspese",
          capacita: ["menu-spese"]
        }
      ]
    },
    {
      module: "Parti",
      path: "progetti/parti",
      title: "menu.PartiLabel",
      icon: "mdi mdi-barcode",
      capacita: ["menu-parti"]
    },
    {
      module: "Parti",
      path: "progetti/parti/categorie-list",
      title: "Categorie Parti",
      icon: "mdi mdi-cart",
      capacita: ["menu-parti"]
    },
    {
      module: "Spedizioni",
      path: "spedizioni/lista",
      title: "menu.SpedizioniLabel",
      icon: "mdi mdi-airplane",
      capacita: ["menu-spedizioni"]
    },
    {
      module: "Stampe",
      path: "stampe/templates",
      title: "menu.StampeLabel",
      icon: "mdi mdi-printer",
      class: "has-arrow",
      capacita: ["menu-stampa"],
      submenu: [
        {
          path: "stampe/templates",
          title: "Templates",
          capacita: ["gestione-stampa"]
        },
        {
          path: "stampe/marker",
          title: "Marker",
          capacita: ["menu-stampa"]
        }
      ]
    },
    {
      module: "Settings",
      path: "settings/settings",
      title: "menu.SettingsLabel",
      icon: "mdi-settings",
      capacita: ["menu-settings"]
    },
    {
      module: "Settings",
      path: "settings/smtp",
      title: "menu.smtp",
      icon: "mdi-settings",
      capacita: ["menu-settings"]
    },
    {
      module: "Admin",
      path: "listautenti/roles",
      title: "Ruoli",
      icon: "mdi mdi-lock-open-outline",
      capacita: ["menu-ruoli"]
    },
    {
      module: "Ticket",
      path: "ticket/list",
      title: "Tickets",
      icon: "mdi mdi-bookmark-outline",
      capacita: ["menu-ticket"]
    },
    {
      notruoliOperativi: ["CA","AC"],
      module: "Ecommerce",
      title: 'menu.EcommerceLabel',
      icon: 'mdi mdi-cart',
      class: 'has-arrow',
      submenu: [
        {
          path: '/ecom/cat',
          title: 'Products',
          icon: 'mdi mdi-cards-variant',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          submenu: []
        },
        {
          path: '/ecom/cart',
          title: 'Cart',
          icon: 'mdi mdi-cart',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          submenu: []
        },
        {
          path: '/ecom/orders',
          title: 'Orders',
          icon: 'mdi mdi-chart-pie',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          submenu: []
        }
      ]
    },
    {
      ruoliOperativi:["AC","C","CA"],
      path: 'Cliente/profile',
      title: "Area Riservata",
      icon: 'mdi mdi-cart',
    }
  ],
  //#endregion
};
