import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import {HttpHeaders} from "@angular/common/http";
import {AuthenticationService} from "./Login/_services/authentication.service";

@Injectable({
  providedIn: 'root'
})
export class ApiPathsService {

constructor(private authenticationService : AuthenticationService) { }
 /**
   * URL di base dell'API
   */
  private baseAPI = environment.apiUrl;
  private bbbAPI = environment.bbbUrl;
  private socketURL = '';
  private socketPort = '';

  public getBaseAPI = () => this.baseAPI;
  public setBaseAPI = (val: string) => this.baseAPI = val;
  public getBbbAPI = () => this.bbbAPI;
  public getSocketURL = () => this.socketURL;
  public getSocketPort = () => this.socketPort;

  public options() {
    return {
      headers: this.headers()
    };
  }
  public headers = () => new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${this.authenticationService?.currentUserValue?.access_token}`,
    })

}

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

}

